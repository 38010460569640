import React, { useState } from 'react'
import styled from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Link, navigate } from 'gatsby'
import Img from 'gatsby-image'
import { IPost } from '~/types'
import { contentWidth, height16x9, height4x3, linesRem } from '~/utils/styling'
import { GatsbyImageSharpFluidFragment } from '~/types'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { useAppContext } from '~/contexts/appContext'

interface IBookListItemProps {
  book: IPost
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: start;
  text-align: center;
  flex-basis: 140px;
  margin: 10px 0;
  .space {
    flex: 1;
  }
  .img {
    display: flex;
    flex-direction: column;
    width: 160px;
    margin: 0 10px;
    height: 265px;
    cursor: pointer;
    /* background: gold; */
  }
  .book-title {
    margin: 10px 0 0;
    font-size: 80%;
    font-weight: bold;
    text-transform: uppercase;
  }
  @media (max-width: 540px) {
    .img {
      width: 200px;
      height: 333px;
    }
    .book-title {
      font-size: 90%;
    }
  }
  @media (max-width: 440px) {
    .img {
      width: 360px;
      height: auto;
      cursor: default;
    }
    .space {
      flex: 0;
    }
    .book-title {
      margin: 10px 0 20px;
      font-size: 120%;
    }
  }
`

const BookListItem: React.FC<IBookListItemProps> = ({ book }) => {
  const { state } = useAppContext()
  let image = null

  if (book.cover) {
    image = (
      <Img
        fluid={book.cover as GatsbyImageSharpFluidFragment}
        style={{ maxHeight: `${height4x3}px` }}
        imgStyle={{
          objectFit: 'contain',
          // border: `3px solid white`,
          // maxHeight: `${height4x3}px`,
        }}
      />
    )
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)

  function toggleModal() {
    if (state.pageWidth <= 400) return
    setModalIsOpen(!modalIsOpen)
  }

  return (
    <StyledDiv>
      {/* <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={toggleModal} allowFullscreen={false}>
            <Carousel views={[book.cover]} components={{ Footer: null }} />
          </Modal>
        ) : null}
      </ModalGateway> */}
      <div>
        <div className="img" onClick={() => navigate(book.urlPath)}>
          <div className='space'></div>
          {image}
        </div>
      </div>
      <div className='book-title'>
        <Link to={book.urlPath}>{book.title}</Link>
      </div>
    </StyledDiv>
  )
}

export default BookListItem
