import React from 'react'
import styled from 'styled-components'
import { linesRem } from '~/utils/styling'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-items: start;
  justify-content: center;
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    text-shadow: 0 1px 0 white;
    a:active,
    a:hover {
      text-shadow: none;
    }
  }
`

export const BooksContainer: React.FC = ({ children }) => {
  return <StyledDiv className="fixed-width mb-5">{children}</StyledDiv>
}

export default BooksContainer