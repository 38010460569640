import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import { BooksForPageQuery, IGasbyPageProps, IPost } from '~/types'
import SEO from '~/components/SEO'
import BookListItem from '~/components/BookListItem'
import BooksContainer from '~/components/BooksContainer'
import Pagination from '~/components/Pagination'
import { formatPostListFromData } from '~/utils/post'
import styled from 'styled-components'

interface IPostListTemplatePageContext {
  currentPage: number
  numPages: number
}

interface IBookListTemplateProps extends IGasbyPageProps {
  data: BooksForPageQuery
  pageContext: IPostListTemplatePageContext
}

const Subheading = styled.h3`
  color: #999;
  text-transform: uppercase;
  font-size: 20px;
`

const BookListTemplate: React.FC<IBookListTemplateProps> = ({
  data,
  uri,
  pageContext,
}) => {
  const books = formatPostListFromData(data)//.sort((a,b) => a.year === b.year ? a.title.localeCompare(b.title) : b.year - a.year)
  // const booksMap = new Map()

  // data.books.nodes.forEach(e => booksMap.set(e.urlKey, e))

  const { currentPage, numPages } = pageContext
  return (
    <Layout uri={uri}>
      <div className="fixed-width mt-4">
        <Subheading>Мои книги</Subheading>
      </div>
      <BooksContainer>
        {books.map((post, index) => (
          <BookListItem book={post} key={index} />
        ))}
      </BooksContainer>
      <SEO
        title="Книги"
        robots="index,follow"
        description="Книги протоиерея Артемия Владимирова. Официальный сайт."
      ></SEO>
    </Layout>
  )
}

export default BookListTemplate

export const query = graphql`
  query booksForPage($contentWidth: Int!) {
    allMdx(
      sort: { fields: [frontmatter___year, frontmatter___title], order: [DESC, ASC] }
      filter: {
        frontmatter: { draft: { ne: true } }
        fields: { urlPath: { ne: null }, source: { eq: "books" } }
      }
    ) {
      edges {
        node {
          ...PostDataMdxFragment
        }
      }
    }
  }
`
